import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Routes, Route } from "react-router-dom";
import React, { useState } from "react";
import UserLoginForm from "./components/UserLogin";
import Navbar from "./components/Navbar2";
import Homepage from './components/Homepage';
import About from './components/About';
import UserContext from "./context/UserContext";

function App() {
  const clientId = '427365678396-bl9efe7tkn7219f0v8ni8cbijedmbfge.apps.googleusercontent.com';

  const [userInfo, setUserInfo] = useState({
    access_token: null,
    username: null
  });

  const verifyToken = async (access_token, username) => {
    try {
      const response = await fetch("https://flipbook.upd.edu.ph/api/token/verify/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: access_token }),
      });

      if (response.ok) {
        setUserInfo({
          access_token,
          username,
        });
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("username", username);
      } else {
        throw new Error("Token verification failed");
      }
    } catch (error) {
      console.error("Token verification error:", error);
      setUserInfo({ access_token: null, username: null });
      localStorage.removeItem("access_token");
      localStorage.removeItem("username");
    }
  };

  const updateUserInfo = (value) => {
    setUserInfo(value);
  };

  return (
    <UserContext.Provider value={{ userInfo, updateUserInfo, verifyToken }}>
      <GoogleOAuthProvider clientId={clientId}>
        <div className="bg-up">
          <Navbar />
          <UserLoginForm />
          <Routes>
            <Route path="/home" element={<Homepage />} />
            <Route path="/about" element={<About />} />
          </Routes>
        </div>
      </GoogleOAuthProvider>
    </UserContext.Provider>
  );
}

export default App;
